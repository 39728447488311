main {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	align-items: center;
	justify-content: center;
	font-family: "motiva-sans", sans-serif;
	text-align: center;
}

.image {
	max-height: 250px;
	max-width: 300px;
}

.spacer {
	flex-grow: 1;
}

h1 {
	font-weight: 900;
	font-size: calc(10px + 4vmin);
	color: #ee7975;
	margin: 0;
	padding: 0;
	text-transform: capitalize;
}

.desc {
	font-size: calc(10px + 2vmin);
	color: #ee7975;
	margin-top: 0;
	font-weight: 600;
}

.descSpan {
	font-weight: 900;
	font-size: calc(10px + 31vmin);
	text-transform: uppercase;
}

.ageStatement {
	font-weight: 600;
	color: white;
}

button {
	/* Green */
	border: none;
	color: white;
	padding: 15px 32px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	margin: 1em;
	font-weight: 600;
}

button:hover {
	opacity: 0.5;
	cursor: pointer;
}

.notOfAge {
	background-color: #ee7975; /* Green */
}

.isOfAge {
	background-color: #4caf50; /* Green */
}

.about {
	margin-bottom: 2em;
}

a {
	color: white;
	text-decoration: none;
}
